.custom-table-header {
  background: #F4F7FA;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 20px;
  border-bottom: 1px solid #ECEEF1;
  gap: 20px;
}

.custom-table-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #889099;
  padding: 13px 0;
  padding-right: 20px;
  min-width: 140px;

  &.id {
    max-width: 30px;
    min-width: 30px;
  }

  &.photo {
    max-width: 100px;
    min-width: 100px;
  }

  &.select {
    max-width: 18px;
    min-width: 18px;
  }

  &.status {
    min-width: 60px;
    max-width: 60px;
  }

  &.actions {
    justify-content: flex-end;
    padding-right: 0;
    z-index: 1;
  }

  .btn-sort {
    display: flex;
    align-items: center;
    position: relative;
    background-color: transparent;
    padding: 0;
    padding-right: 5px;
    border: none;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #889099;
    cursor: pointer;
    text-align: left;
    white-space: nowrap;

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      position: absolute;
      right: -15px;
      top: -1px;
      display: inline-block;
      flex: none;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%23889099'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%23889099'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
    }

    &.active {
      color: #00D0B3;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%2300D0B3'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%2300D0B3'/%3E%3C/svg%3E%0A");
      }
    }

    &.sort-asc {
      &::after {
        transform: rotate(-180deg);
      }
    }

    &.sort-desc {
      &::after {
        transform: rotate(0);
      }
    }

    &.not-sort {
      &::after {
        content: none;
      }
    }
  }

}

.custom-table-item-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ECEEF1;
  padding: 0px 20px;
  gap: 20px;

  .custom-table-cell {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    height: auto;
    padding: 0;
    padding-right: 20px;

    &.actions {
      padding-right: 0;
      z-index: 1;
    }
  }
}

.custom-table-body {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
}