@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~swiper/dist/css/swiper.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "~ngx-toastr/toastr.css";
@import url("https://fonts.cdnfonts.com/css/junegull");
@font-face {
  font-family: "mesh-icons";
  src: url("assets/fonts/mesh-icons/font/mesh-icons.eot?63541697");
  src: url("assets/fonts/mesh-icons/font/mesh-icons.eot?63541697#iefix") format("embedded-opentype"), url("assets/fonts/mesh-icons/font/mesh-icons.woff2?63541697") format("woff2"), url("assets/fonts/mesh-icons/font/mesh-icons.woff?63541697") format("woff"), url("assets/fonts/mesh-icons/font/mesh-icons.ttf?63541697") format("truetype"), url("assets/fonts/mesh-icons/font/mesh-icons.svg?63541697#mesh-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mesh-icons';
    src: url('../font/mesh-icons.svg?63541697#mesh-icons') format('svg');
  }
}
*/
[class^=mesh-icon-]:before, [class*=" mesh-icon-"]:before {
  font-family: "mesh-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.mesh-icon-paper-clip:before {
  content: "";
}

/* '' */
.mesh-icon-likes:before {
  content: "";
}

/* '' */
.mesh-icon-views:before {
  content: "";
}

/* '' */
.mesh-icon-comments:before {
  content: "";
}

/* '' */
.mesh-icon-file:before {
  content: "";
}

/* '' */
.mesh-icon-emo-happy:before {
  content: "";
}

/* '' */
.mesh-icon-paper-plane:before {
  content: "";
}

/* '' */
.mesh-icon-dropzone-remove-badge:before {
  content: "";
}

/* '' */
.mesh-icon-emo-devil:before {
  content: "";
}

/* '' */
.mesh-icon-emo-sunglasses:before {
  content: "";
}

/* '' */
.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 15px 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.ph-item, .ph-item *,
.ph-item ::after, .ph-item ::before {
  box-sizing: border-box;
}
.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}
.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 15px;
  padding-left: 15px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}
.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
}
.ph-row .big, .ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}
.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.6666666667%;
}

.ph-col-4 {
  flex: 0 0 33.3333333333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.6666666667%;
}

.ph-col-10 {
  flex: 0 0 83.3333333333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #ced4da;
  margin-bottom: 15px;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
.cursor-pointer {
  cursor: pointer !important;
}

.hover-opacity-8:hover {
  opacity: 0.8;
}

.mesh {
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
  /*  margins */
  /*  paddings */
  /* font-size */
}
.mesh-font-segoe {
  font-family: Segoe UI !important;
}
.mesh-text-secondary {
  color: #626262 !important;
}
.mesh-hover-opacity:hover {
  opacity: 0.7;
}
.mesh-hover-bg:hover {
  background-color: #d3d3d3;
  border-radius: 3px;
}
.mesh-font-w-1 {
  font-weight: 100;
}
.mesh-font-w-2 {
  font-weight: 200;
}
.mesh-font-w-3 {
  font-weight: 300;
}
.mesh-font-w-4 {
  font-weight: 400;
}
.mesh-font-w-5 {
  font-weight: 500;
}
.mesh-font-w-6 {
  font-weight: 600;
}
.mesh-font-w-7 {
  font-weight: 700;
}
.mesh-font-w-8 {
  font-weight: 800;
}
.mesh-font-w-9 {
  font-weight: 900;
}
.mesh-scale-1 {
  transform: scale(0.1) !important;
}
.mesh-scale-2 {
  transform: scale(0.2) !important;
}
.mesh-scale-3 {
  transform: scale(0.3) !important;
}
.mesh-scale-4 {
  transform: scale(0.4) !important;
}
.mesh-scale-5 {
  transform: scale(0.5) !important;
}
.mesh-scale-6 {
  transform: scale(0.6) !important;
}
.mesh-scale-7 {
  transform: scale(0.7) !important;
}
.mesh-scale-8 {
  transform: scale(0.8) !important;
}
.mesh-scale-9 {
  transform: scale(0.9) !important;
}
.mesh-scale-10 {
  transform: scale(1) !important;
}
.mesh-scale-11 {
  transform: scale(1.1) !important;
}
.mesh-scale-12 {
  transform: scale(1.2) !important;
}
.mesh-scale-13 {
  transform: scale(1.3) !important;
}
.mesh-scale-14 {
  transform: scale(1.4) !important;
}
.mesh-scale-15 {
  transform: scale(1.5) !important;
}
.mesh-scale-16 {
  transform: scale(1.6) !important;
}
.mesh-scale-17 {
  transform: scale(1.7) !important;
}
.mesh-scale-18 {
  transform: scale(1.8) !important;
}
.mesh-scale-19 {
  transform: scale(1.9) !important;
}
.mesh-scale-20 {
  transform: scale(2) !important;
}
.mesh-mb-1, .mesh-my-1 {
  margin-bottom: 1px !important;
}
.mesh-mt-1, .mesh-my-1 {
  margin-top: 1px !important;
}
.mesh-mr-1, .mesh-mx-1 {
  margin-right: 1px !important;
}
.mesh-ml-1, .mesh-mx-1 {
  margin-left: 1px !important;
}
.mesh-pb-1, .mesh-py-1 {
  padding-bottom: 1px !important;
}
.mesh-pt-1, .mesh-py-1 {
  padding-top: 1px !important;
}
.mesh-pr-1, .mesh-px-1 {
  padding-right: 1px !important;
}
.mesh-pl-1, .mesh-px-1 {
  padding-left: 1px !important;
}
.mesh-fs-1 {
  font-size: 1px;
}
.mesh-mb-2, .mesh-my-2 {
  margin-bottom: 2px !important;
}
.mesh-mt-2, .mesh-my-2 {
  margin-top: 2px !important;
}
.mesh-mr-2, .mesh-mx-2 {
  margin-right: 2px !important;
}
.mesh-ml-2, .mesh-mx-2 {
  margin-left: 2px !important;
}
.mesh-pb-2, .mesh-py-2 {
  padding-bottom: 2px !important;
}
.mesh-pt-2, .mesh-py-2 {
  padding-top: 2px !important;
}
.mesh-pr-2, .mesh-px-2 {
  padding-right: 2px !important;
}
.mesh-pl-2, .mesh-px-2 {
  padding-left: 2px !important;
}
.mesh-fs-2 {
  font-size: 2px;
}
.mesh-mb-3, .mesh-my-3 {
  margin-bottom: 3px !important;
}
.mesh-mt-3, .mesh-my-3 {
  margin-top: 3px !important;
}
.mesh-mr-3, .mesh-mx-3 {
  margin-right: 3px !important;
}
.mesh-ml-3, .mesh-mx-3 {
  margin-left: 3px !important;
}
.mesh-pb-3, .mesh-py-3 {
  padding-bottom: 3px !important;
}
.mesh-pt-3, .mesh-py-3 {
  padding-top: 3px !important;
}
.mesh-pr-3, .mesh-px-3 {
  padding-right: 3px !important;
}
.mesh-pl-3, .mesh-px-3 {
  padding-left: 3px !important;
}
.mesh-fs-3 {
  font-size: 3px;
}
.mesh-mb-4, .mesh-my-4 {
  margin-bottom: 4px !important;
}
.mesh-mt-4, .mesh-my-4 {
  margin-top: 4px !important;
}
.mesh-mr-4, .mesh-mx-4 {
  margin-right: 4px !important;
}
.mesh-ml-4, .mesh-mx-4 {
  margin-left: 4px !important;
}
.mesh-pb-4, .mesh-py-4 {
  padding-bottom: 4px !important;
}
.mesh-pt-4, .mesh-py-4 {
  padding-top: 4px !important;
}
.mesh-pr-4, .mesh-px-4 {
  padding-right: 4px !important;
}
.mesh-pl-4, .mesh-px-4 {
  padding-left: 4px !important;
}
.mesh-fs-4 {
  font-size: 4px;
}
.mesh-mb-5, .mesh-my-5 {
  margin-bottom: 5px !important;
}
.mesh-mt-5, .mesh-my-5 {
  margin-top: 5px !important;
}
.mesh-mr-5, .mesh-mx-5 {
  margin-right: 5px !important;
}
.mesh-ml-5, .mesh-mx-5 {
  margin-left: 5px !important;
}
.mesh-pb-5, .mesh-py-5 {
  padding-bottom: 5px !important;
}
.mesh-pt-5, .mesh-py-5 {
  padding-top: 5px !important;
}
.mesh-pr-5, .mesh-px-5 {
  padding-right: 5px !important;
}
.mesh-pl-5, .mesh-px-5 {
  padding-left: 5px !important;
}
.mesh-fs-5 {
  font-size: 5px;
}
.mesh-mb-6, .mesh-my-6 {
  margin-bottom: 6px !important;
}
.mesh-mt-6, .mesh-my-6 {
  margin-top: 6px !important;
}
.mesh-mr-6, .mesh-mx-6 {
  margin-right: 6px !important;
}
.mesh-ml-6, .mesh-mx-6 {
  margin-left: 6px !important;
}
.mesh-pb-6, .mesh-py-6 {
  padding-bottom: 6px !important;
}
.mesh-pt-6, .mesh-py-6 {
  padding-top: 6px !important;
}
.mesh-pr-6, .mesh-px-6 {
  padding-right: 6px !important;
}
.mesh-pl-6, .mesh-px-6 {
  padding-left: 6px !important;
}
.mesh-fs-6 {
  font-size: 6px;
}
.mesh-mb-7, .mesh-my-7 {
  margin-bottom: 7px !important;
}
.mesh-mt-7, .mesh-my-7 {
  margin-top: 7px !important;
}
.mesh-mr-7, .mesh-mx-7 {
  margin-right: 7px !important;
}
.mesh-ml-7, .mesh-mx-7 {
  margin-left: 7px !important;
}
.mesh-pb-7, .mesh-py-7 {
  padding-bottom: 7px !important;
}
.mesh-pt-7, .mesh-py-7 {
  padding-top: 7px !important;
}
.mesh-pr-7, .mesh-px-7 {
  padding-right: 7px !important;
}
.mesh-pl-7, .mesh-px-7 {
  padding-left: 7px !important;
}
.mesh-fs-7 {
  font-size: 7px;
}
.mesh-mb-8, .mesh-my-8 {
  margin-bottom: 8px !important;
}
.mesh-mt-8, .mesh-my-8 {
  margin-top: 8px !important;
}
.mesh-mr-8, .mesh-mx-8 {
  margin-right: 8px !important;
}
.mesh-ml-8, .mesh-mx-8 {
  margin-left: 8px !important;
}
.mesh-pb-8, .mesh-py-8 {
  padding-bottom: 8px !important;
}
.mesh-pt-8, .mesh-py-8 {
  padding-top: 8px !important;
}
.mesh-pr-8, .mesh-px-8 {
  padding-right: 8px !important;
}
.mesh-pl-8, .mesh-px-8 {
  padding-left: 8px !important;
}
.mesh-fs-8 {
  font-size: 8px;
}
.mesh-mb-9, .mesh-my-9 {
  margin-bottom: 9px !important;
}
.mesh-mt-9, .mesh-my-9 {
  margin-top: 9px !important;
}
.mesh-mr-9, .mesh-mx-9 {
  margin-right: 9px !important;
}
.mesh-ml-9, .mesh-mx-9 {
  margin-left: 9px !important;
}
.mesh-pb-9, .mesh-py-9 {
  padding-bottom: 9px !important;
}
.mesh-pt-9, .mesh-py-9 {
  padding-top: 9px !important;
}
.mesh-pr-9, .mesh-px-9 {
  padding-right: 9px !important;
}
.mesh-pl-9, .mesh-px-9 {
  padding-left: 9px !important;
}
.mesh-fs-9 {
  font-size: 9px;
}
.mesh-mb-10, .mesh-my-10 {
  margin-bottom: 10px !important;
}
.mesh-mt-10, .mesh-my-10 {
  margin-top: 10px !important;
}
.mesh-mr-10, .mesh-mx-10 {
  margin-right: 10px !important;
}
.mesh-ml-10, .mesh-mx-10 {
  margin-left: 10px !important;
}
.mesh-pb-10, .mesh-py-10 {
  padding-bottom: 10px !important;
}
.mesh-pt-10, .mesh-py-10 {
  padding-top: 10px !important;
}
.mesh-pr-10, .mesh-px-10 {
  padding-right: 10px !important;
}
.mesh-pl-10, .mesh-px-10 {
  padding-left: 10px !important;
}
.mesh-fs-10 {
  font-size: 10px;
}
.mesh-mb-11, .mesh-my-11 {
  margin-bottom: 11px !important;
}
.mesh-mt-11, .mesh-my-11 {
  margin-top: 11px !important;
}
.mesh-mr-11, .mesh-mx-11 {
  margin-right: 11px !important;
}
.mesh-ml-11, .mesh-mx-11 {
  margin-left: 11px !important;
}
.mesh-pb-11, .mesh-py-11 {
  padding-bottom: 11px !important;
}
.mesh-pt-11, .mesh-py-11 {
  padding-top: 11px !important;
}
.mesh-pr-11, .mesh-px-11 {
  padding-right: 11px !important;
}
.mesh-pl-11, .mesh-px-11 {
  padding-left: 11px !important;
}
.mesh-fs-11 {
  font-size: 11px;
}
.mesh-mb-12, .mesh-my-12 {
  margin-bottom: 12px !important;
}
.mesh-mt-12, .mesh-my-12 {
  margin-top: 12px !important;
}
.mesh-mr-12, .mesh-mx-12 {
  margin-right: 12px !important;
}
.mesh-ml-12, .mesh-mx-12 {
  margin-left: 12px !important;
}
.mesh-pb-12, .mesh-py-12 {
  padding-bottom: 12px !important;
}
.mesh-pt-12, .mesh-py-12 {
  padding-top: 12px !important;
}
.mesh-pr-12, .mesh-px-12 {
  padding-right: 12px !important;
}
.mesh-pl-12, .mesh-px-12 {
  padding-left: 12px !important;
}
.mesh-fs-12 {
  font-size: 12px;
}
.mesh-mb-13, .mesh-my-13 {
  margin-bottom: 13px !important;
}
.mesh-mt-13, .mesh-my-13 {
  margin-top: 13px !important;
}
.mesh-mr-13, .mesh-mx-13 {
  margin-right: 13px !important;
}
.mesh-ml-13, .mesh-mx-13 {
  margin-left: 13px !important;
}
.mesh-pb-13, .mesh-py-13 {
  padding-bottom: 13px !important;
}
.mesh-pt-13, .mesh-py-13 {
  padding-top: 13px !important;
}
.mesh-pr-13, .mesh-px-13 {
  padding-right: 13px !important;
}
.mesh-pl-13, .mesh-px-13 {
  padding-left: 13px !important;
}
.mesh-fs-13 {
  font-size: 13px;
}
.mesh-mb-14, .mesh-my-14 {
  margin-bottom: 14px !important;
}
.mesh-mt-14, .mesh-my-14 {
  margin-top: 14px !important;
}
.mesh-mr-14, .mesh-mx-14 {
  margin-right: 14px !important;
}
.mesh-ml-14, .mesh-mx-14 {
  margin-left: 14px !important;
}
.mesh-pb-14, .mesh-py-14 {
  padding-bottom: 14px !important;
}
.mesh-pt-14, .mesh-py-14 {
  padding-top: 14px !important;
}
.mesh-pr-14, .mesh-px-14 {
  padding-right: 14px !important;
}
.mesh-pl-14, .mesh-px-14 {
  padding-left: 14px !important;
}
.mesh-fs-14 {
  font-size: 14px;
}
.mesh-mb-15, .mesh-my-15 {
  margin-bottom: 15px !important;
}
.mesh-mt-15, .mesh-my-15 {
  margin-top: 15px !important;
}
.mesh-mr-15, .mesh-mx-15 {
  margin-right: 15px !important;
}
.mesh-ml-15, .mesh-mx-15 {
  margin-left: 15px !important;
}
.mesh-pb-15, .mesh-py-15 {
  padding-bottom: 15px !important;
}
.mesh-pt-15, .mesh-py-15 {
  padding-top: 15px !important;
}
.mesh-pr-15, .mesh-px-15 {
  padding-right: 15px !important;
}
.mesh-pl-15, .mesh-px-15 {
  padding-left: 15px !important;
}
.mesh-fs-15 {
  font-size: 15px;
}
.mesh-mb-16, .mesh-my-16 {
  margin-bottom: 16px !important;
}
.mesh-mt-16, .mesh-my-16 {
  margin-top: 16px !important;
}
.mesh-mr-16, .mesh-mx-16 {
  margin-right: 16px !important;
}
.mesh-ml-16, .mesh-mx-16 {
  margin-left: 16px !important;
}
.mesh-pb-16, .mesh-py-16 {
  padding-bottom: 16px !important;
}
.mesh-pt-16, .mesh-py-16 {
  padding-top: 16px !important;
}
.mesh-pr-16, .mesh-px-16 {
  padding-right: 16px !important;
}
.mesh-pl-16, .mesh-px-16 {
  padding-left: 16px !important;
}
.mesh-fs-16 {
  font-size: 16px;
}
.mesh-mb-17, .mesh-my-17 {
  margin-bottom: 17px !important;
}
.mesh-mt-17, .mesh-my-17 {
  margin-top: 17px !important;
}
.mesh-mr-17, .mesh-mx-17 {
  margin-right: 17px !important;
}
.mesh-ml-17, .mesh-mx-17 {
  margin-left: 17px !important;
}
.mesh-pb-17, .mesh-py-17 {
  padding-bottom: 17px !important;
}
.mesh-pt-17, .mesh-py-17 {
  padding-top: 17px !important;
}
.mesh-pr-17, .mesh-px-17 {
  padding-right: 17px !important;
}
.mesh-pl-17, .mesh-px-17 {
  padding-left: 17px !important;
}
.mesh-fs-17 {
  font-size: 17px;
}
.mesh-mb-18, .mesh-my-18 {
  margin-bottom: 18px !important;
}
.mesh-mt-18, .mesh-my-18 {
  margin-top: 18px !important;
}
.mesh-mr-18, .mesh-mx-18 {
  margin-right: 18px !important;
}
.mesh-ml-18, .mesh-mx-18 {
  margin-left: 18px !important;
}
.mesh-pb-18, .mesh-py-18 {
  padding-bottom: 18px !important;
}
.mesh-pt-18, .mesh-py-18 {
  padding-top: 18px !important;
}
.mesh-pr-18, .mesh-px-18 {
  padding-right: 18px !important;
}
.mesh-pl-18, .mesh-px-18 {
  padding-left: 18px !important;
}
.mesh-fs-18 {
  font-size: 18px;
}
.mesh-mb-19, .mesh-my-19 {
  margin-bottom: 19px !important;
}
.mesh-mt-19, .mesh-my-19 {
  margin-top: 19px !important;
}
.mesh-mr-19, .mesh-mx-19 {
  margin-right: 19px !important;
}
.mesh-ml-19, .mesh-mx-19 {
  margin-left: 19px !important;
}
.mesh-pb-19, .mesh-py-19 {
  padding-bottom: 19px !important;
}
.mesh-pt-19, .mesh-py-19 {
  padding-top: 19px !important;
}
.mesh-pr-19, .mesh-px-19 {
  padding-right: 19px !important;
}
.mesh-pl-19, .mesh-px-19 {
  padding-left: 19px !important;
}
.mesh-fs-19 {
  font-size: 19px;
}
.mesh-mb-20, .mesh-my-20 {
  margin-bottom: 20px !important;
}
.mesh-mt-20, .mesh-my-20 {
  margin-top: 20px !important;
}
.mesh-mr-20, .mesh-mx-20 {
  margin-right: 20px !important;
}
.mesh-ml-20, .mesh-mx-20 {
  margin-left: 20px !important;
}
.mesh-pb-20, .mesh-py-20 {
  padding-bottom: 20px !important;
}
.mesh-pt-20, .mesh-py-20 {
  padding-top: 20px !important;
}
.mesh-pr-20, .mesh-px-20 {
  padding-right: 20px !important;
}
.mesh-pl-20, .mesh-px-20 {
  padding-left: 20px !important;
}
.mesh-fs-20 {
  font-size: 20px;
}
.mesh-mb-21, .mesh-my-21 {
  margin-bottom: 21px !important;
}
.mesh-mt-21, .mesh-my-21 {
  margin-top: 21px !important;
}
.mesh-mr-21, .mesh-mx-21 {
  margin-right: 21px !important;
}
.mesh-ml-21, .mesh-mx-21 {
  margin-left: 21px !important;
}
.mesh-pb-21, .mesh-py-21 {
  padding-bottom: 21px !important;
}
.mesh-pt-21, .mesh-py-21 {
  padding-top: 21px !important;
}
.mesh-pr-21, .mesh-px-21 {
  padding-right: 21px !important;
}
.mesh-pl-21, .mesh-px-21 {
  padding-left: 21px !important;
}
.mesh-fs-21 {
  font-size: 21px;
}
.mesh-mb-22, .mesh-my-22 {
  margin-bottom: 22px !important;
}
.mesh-mt-22, .mesh-my-22 {
  margin-top: 22px !important;
}
.mesh-mr-22, .mesh-mx-22 {
  margin-right: 22px !important;
}
.mesh-ml-22, .mesh-mx-22 {
  margin-left: 22px !important;
}
.mesh-pb-22, .mesh-py-22 {
  padding-bottom: 22px !important;
}
.mesh-pt-22, .mesh-py-22 {
  padding-top: 22px !important;
}
.mesh-pr-22, .mesh-px-22 {
  padding-right: 22px !important;
}
.mesh-pl-22, .mesh-px-22 {
  padding-left: 22px !important;
}
.mesh-fs-22 {
  font-size: 22px;
}
.mesh-mb-23, .mesh-my-23 {
  margin-bottom: 23px !important;
}
.mesh-mt-23, .mesh-my-23 {
  margin-top: 23px !important;
}
.mesh-mr-23, .mesh-mx-23 {
  margin-right: 23px !important;
}
.mesh-ml-23, .mesh-mx-23 {
  margin-left: 23px !important;
}
.mesh-pb-23, .mesh-py-23 {
  padding-bottom: 23px !important;
}
.mesh-pt-23, .mesh-py-23 {
  padding-top: 23px !important;
}
.mesh-pr-23, .mesh-px-23 {
  padding-right: 23px !important;
}
.mesh-pl-23, .mesh-px-23 {
  padding-left: 23px !important;
}
.mesh-fs-23 {
  font-size: 23px;
}
.mesh-mb-24, .mesh-my-24 {
  margin-bottom: 24px !important;
}
.mesh-mt-24, .mesh-my-24 {
  margin-top: 24px !important;
}
.mesh-mr-24, .mesh-mx-24 {
  margin-right: 24px !important;
}
.mesh-ml-24, .mesh-mx-24 {
  margin-left: 24px !important;
}
.mesh-pb-24, .mesh-py-24 {
  padding-bottom: 24px !important;
}
.mesh-pt-24, .mesh-py-24 {
  padding-top: 24px !important;
}
.mesh-pr-24, .mesh-px-24 {
  padding-right: 24px !important;
}
.mesh-pl-24, .mesh-px-24 {
  padding-left: 24px !important;
}
.mesh-fs-24 {
  font-size: 24px;
}
.mesh-mb-25, .mesh-my-25 {
  margin-bottom: 25px !important;
}
.mesh-mt-25, .mesh-my-25 {
  margin-top: 25px !important;
}
.mesh-mr-25, .mesh-mx-25 {
  margin-right: 25px !important;
}
.mesh-ml-25, .mesh-mx-25 {
  margin-left: 25px !important;
}
.mesh-pb-25, .mesh-py-25 {
  padding-bottom: 25px !important;
}
.mesh-pt-25, .mesh-py-25 {
  padding-top: 25px !important;
}
.mesh-pr-25, .mesh-px-25 {
  padding-right: 25px !important;
}
.mesh-pl-25, .mesh-px-25 {
  padding-left: 25px !important;
}
.mesh-fs-25 {
  font-size: 25px;
}
.mesh-mb-26, .mesh-my-26 {
  margin-bottom: 26px !important;
}
.mesh-mt-26, .mesh-my-26 {
  margin-top: 26px !important;
}
.mesh-mr-26, .mesh-mx-26 {
  margin-right: 26px !important;
}
.mesh-ml-26, .mesh-mx-26 {
  margin-left: 26px !important;
}
.mesh-pb-26, .mesh-py-26 {
  padding-bottom: 26px !important;
}
.mesh-pt-26, .mesh-py-26 {
  padding-top: 26px !important;
}
.mesh-pr-26, .mesh-px-26 {
  padding-right: 26px !important;
}
.mesh-pl-26, .mesh-px-26 {
  padding-left: 26px !important;
}
.mesh-fs-26 {
  font-size: 26px;
}
.mesh-mb-27, .mesh-my-27 {
  margin-bottom: 27px !important;
}
.mesh-mt-27, .mesh-my-27 {
  margin-top: 27px !important;
}
.mesh-mr-27, .mesh-mx-27 {
  margin-right: 27px !important;
}
.mesh-ml-27, .mesh-mx-27 {
  margin-left: 27px !important;
}
.mesh-pb-27, .mesh-py-27 {
  padding-bottom: 27px !important;
}
.mesh-pt-27, .mesh-py-27 {
  padding-top: 27px !important;
}
.mesh-pr-27, .mesh-px-27 {
  padding-right: 27px !important;
}
.mesh-pl-27, .mesh-px-27 {
  padding-left: 27px !important;
}
.mesh-fs-27 {
  font-size: 27px;
}
.mesh-mb-28, .mesh-my-28 {
  margin-bottom: 28px !important;
}
.mesh-mt-28, .mesh-my-28 {
  margin-top: 28px !important;
}
.mesh-mr-28, .mesh-mx-28 {
  margin-right: 28px !important;
}
.mesh-ml-28, .mesh-mx-28 {
  margin-left: 28px !important;
}
.mesh-pb-28, .mesh-py-28 {
  padding-bottom: 28px !important;
}
.mesh-pt-28, .mesh-py-28 {
  padding-top: 28px !important;
}
.mesh-pr-28, .mesh-px-28 {
  padding-right: 28px !important;
}
.mesh-pl-28, .mesh-px-28 {
  padding-left: 28px !important;
}
.mesh-fs-28 {
  font-size: 28px;
}
.mesh-mb-29, .mesh-my-29 {
  margin-bottom: 29px !important;
}
.mesh-mt-29, .mesh-my-29 {
  margin-top: 29px !important;
}
.mesh-mr-29, .mesh-mx-29 {
  margin-right: 29px !important;
}
.mesh-ml-29, .mesh-mx-29 {
  margin-left: 29px !important;
}
.mesh-pb-29, .mesh-py-29 {
  padding-bottom: 29px !important;
}
.mesh-pt-29, .mesh-py-29 {
  padding-top: 29px !important;
}
.mesh-pr-29, .mesh-px-29 {
  padding-right: 29px !important;
}
.mesh-pl-29, .mesh-px-29 {
  padding-left: 29px !important;
}
.mesh-fs-29 {
  font-size: 29px;
}
.mesh-mb-30, .mesh-my-30 {
  margin-bottom: 30px !important;
}
.mesh-mt-30, .mesh-my-30 {
  margin-top: 30px !important;
}
.mesh-mr-30, .mesh-mx-30 {
  margin-right: 30px !important;
}
.mesh-ml-30, .mesh-mx-30 {
  margin-left: 30px !important;
}
.mesh-pb-30, .mesh-py-30 {
  padding-bottom: 30px !important;
}
.mesh-pt-30, .mesh-py-30 {
  padding-top: 30px !important;
}
.mesh-pr-30, .mesh-px-30 {
  padding-right: 30px !important;
}
.mesh-pl-30, .mesh-px-30 {
  padding-left: 30px !important;
}
.mesh-fs-30 {
  font-size: 30px;
}
.mesh-mb-31, .mesh-my-31 {
  margin-bottom: 31px !important;
}
.mesh-mt-31, .mesh-my-31 {
  margin-top: 31px !important;
}
.mesh-mr-31, .mesh-mx-31 {
  margin-right: 31px !important;
}
.mesh-ml-31, .mesh-mx-31 {
  margin-left: 31px !important;
}
.mesh-pb-31, .mesh-py-31 {
  padding-bottom: 31px !important;
}
.mesh-pt-31, .mesh-py-31 {
  padding-top: 31px !important;
}
.mesh-pr-31, .mesh-px-31 {
  padding-right: 31px !important;
}
.mesh-pl-31, .mesh-px-31 {
  padding-left: 31px !important;
}
.mesh-fs-31 {
  font-size: 31px;
}
.mesh-mb-32, .mesh-my-32 {
  margin-bottom: 32px !important;
}
.mesh-mt-32, .mesh-my-32 {
  margin-top: 32px !important;
}
.mesh-mr-32, .mesh-mx-32 {
  margin-right: 32px !important;
}
.mesh-ml-32, .mesh-mx-32 {
  margin-left: 32px !important;
}
.mesh-pb-32, .mesh-py-32 {
  padding-bottom: 32px !important;
}
.mesh-pt-32, .mesh-py-32 {
  padding-top: 32px !important;
}
.mesh-pr-32, .mesh-px-32 {
  padding-right: 32px !important;
}
.mesh-pl-32, .mesh-px-32 {
  padding-left: 32px !important;
}
.mesh-fs-32 {
  font-size: 32px;
}
.mesh-mb-33, .mesh-my-33 {
  margin-bottom: 33px !important;
}
.mesh-mt-33, .mesh-my-33 {
  margin-top: 33px !important;
}
.mesh-mr-33, .mesh-mx-33 {
  margin-right: 33px !important;
}
.mesh-ml-33, .mesh-mx-33 {
  margin-left: 33px !important;
}
.mesh-pb-33, .mesh-py-33 {
  padding-bottom: 33px !important;
}
.mesh-pt-33, .mesh-py-33 {
  padding-top: 33px !important;
}
.mesh-pr-33, .mesh-px-33 {
  padding-right: 33px !important;
}
.mesh-pl-33, .mesh-px-33 {
  padding-left: 33px !important;
}
.mesh-fs-33 {
  font-size: 33px;
}
.mesh-mb-34, .mesh-my-34 {
  margin-bottom: 34px !important;
}
.mesh-mt-34, .mesh-my-34 {
  margin-top: 34px !important;
}
.mesh-mr-34, .mesh-mx-34 {
  margin-right: 34px !important;
}
.mesh-ml-34, .mesh-mx-34 {
  margin-left: 34px !important;
}
.mesh-pb-34, .mesh-py-34 {
  padding-bottom: 34px !important;
}
.mesh-pt-34, .mesh-py-34 {
  padding-top: 34px !important;
}
.mesh-pr-34, .mesh-px-34 {
  padding-right: 34px !important;
}
.mesh-pl-34, .mesh-px-34 {
  padding-left: 34px !important;
}
.mesh-fs-34 {
  font-size: 34px;
}
.mesh-mb-35, .mesh-my-35 {
  margin-bottom: 35px !important;
}
.mesh-mt-35, .mesh-my-35 {
  margin-top: 35px !important;
}
.mesh-mr-35, .mesh-mx-35 {
  margin-right: 35px !important;
}
.mesh-ml-35, .mesh-mx-35 {
  margin-left: 35px !important;
}
.mesh-pb-35, .mesh-py-35 {
  padding-bottom: 35px !important;
}
.mesh-pt-35, .mesh-py-35 {
  padding-top: 35px !important;
}
.mesh-pr-35, .mesh-px-35 {
  padding-right: 35px !important;
}
.mesh-pl-35, .mesh-px-35 {
  padding-left: 35px !important;
}
.mesh-fs-35 {
  font-size: 35px;
}
.mesh-mb-36, .mesh-my-36 {
  margin-bottom: 36px !important;
}
.mesh-mt-36, .mesh-my-36 {
  margin-top: 36px !important;
}
.mesh-mr-36, .mesh-mx-36 {
  margin-right: 36px !important;
}
.mesh-ml-36, .mesh-mx-36 {
  margin-left: 36px !important;
}
.mesh-pb-36, .mesh-py-36 {
  padding-bottom: 36px !important;
}
.mesh-pt-36, .mesh-py-36 {
  padding-top: 36px !important;
}
.mesh-pr-36, .mesh-px-36 {
  padding-right: 36px !important;
}
.mesh-pl-36, .mesh-px-36 {
  padding-left: 36px !important;
}
.mesh-fs-36 {
  font-size: 36px;
}
.mesh-mb-37, .mesh-my-37 {
  margin-bottom: 37px !important;
}
.mesh-mt-37, .mesh-my-37 {
  margin-top: 37px !important;
}
.mesh-mr-37, .mesh-mx-37 {
  margin-right: 37px !important;
}
.mesh-ml-37, .mesh-mx-37 {
  margin-left: 37px !important;
}
.mesh-pb-37, .mesh-py-37 {
  padding-bottom: 37px !important;
}
.mesh-pt-37, .mesh-py-37 {
  padding-top: 37px !important;
}
.mesh-pr-37, .mesh-px-37 {
  padding-right: 37px !important;
}
.mesh-pl-37, .mesh-px-37 {
  padding-left: 37px !important;
}
.mesh-fs-37 {
  font-size: 37px;
}
.mesh-mb-38, .mesh-my-38 {
  margin-bottom: 38px !important;
}
.mesh-mt-38, .mesh-my-38 {
  margin-top: 38px !important;
}
.mesh-mr-38, .mesh-mx-38 {
  margin-right: 38px !important;
}
.mesh-ml-38, .mesh-mx-38 {
  margin-left: 38px !important;
}
.mesh-pb-38, .mesh-py-38 {
  padding-bottom: 38px !important;
}
.mesh-pt-38, .mesh-py-38 {
  padding-top: 38px !important;
}
.mesh-pr-38, .mesh-px-38 {
  padding-right: 38px !important;
}
.mesh-pl-38, .mesh-px-38 {
  padding-left: 38px !important;
}
.mesh-fs-38 {
  font-size: 38px;
}
.mesh-mb-39, .mesh-my-39 {
  margin-bottom: 39px !important;
}
.mesh-mt-39, .mesh-my-39 {
  margin-top: 39px !important;
}
.mesh-mr-39, .mesh-mx-39 {
  margin-right: 39px !important;
}
.mesh-ml-39, .mesh-mx-39 {
  margin-left: 39px !important;
}
.mesh-pb-39, .mesh-py-39 {
  padding-bottom: 39px !important;
}
.mesh-pt-39, .mesh-py-39 {
  padding-top: 39px !important;
}
.mesh-pr-39, .mesh-px-39 {
  padding-right: 39px !important;
}
.mesh-pl-39, .mesh-px-39 {
  padding-left: 39px !important;
}
.mesh-fs-39 {
  font-size: 39px;
}
.mesh-mb-40, .mesh-my-40 {
  margin-bottom: 40px !important;
}
.mesh-mt-40, .mesh-my-40 {
  margin-top: 40px !important;
}
.mesh-mr-40, .mesh-mx-40 {
  margin-right: 40px !important;
}
.mesh-ml-40, .mesh-mx-40 {
  margin-left: 40px !important;
}
.mesh-pb-40, .mesh-py-40 {
  padding-bottom: 40px !important;
}
.mesh-pt-40, .mesh-py-40 {
  padding-top: 40px !important;
}
.mesh-pr-40, .mesh-px-40 {
  padding-right: 40px !important;
}
.mesh-pl-40, .mesh-px-40 {
  padding-left: 40px !important;
}
.mesh-fs-40 {
  font-size: 40px;
}
.mesh-mb-41, .mesh-my-41 {
  margin-bottom: 41px !important;
}
.mesh-mt-41, .mesh-my-41 {
  margin-top: 41px !important;
}
.mesh-mr-41, .mesh-mx-41 {
  margin-right: 41px !important;
}
.mesh-ml-41, .mesh-mx-41 {
  margin-left: 41px !important;
}
.mesh-pb-41, .mesh-py-41 {
  padding-bottom: 41px !important;
}
.mesh-pt-41, .mesh-py-41 {
  padding-top: 41px !important;
}
.mesh-pr-41, .mesh-px-41 {
  padding-right: 41px !important;
}
.mesh-pl-41, .mesh-px-41 {
  padding-left: 41px !important;
}
.mesh-fs-41 {
  font-size: 41px;
}
.mesh-mb-42, .mesh-my-42 {
  margin-bottom: 42px !important;
}
.mesh-mt-42, .mesh-my-42 {
  margin-top: 42px !important;
}
.mesh-mr-42, .mesh-mx-42 {
  margin-right: 42px !important;
}
.mesh-ml-42, .mesh-mx-42 {
  margin-left: 42px !important;
}
.mesh-pb-42, .mesh-py-42 {
  padding-bottom: 42px !important;
}
.mesh-pt-42, .mesh-py-42 {
  padding-top: 42px !important;
}
.mesh-pr-42, .mesh-px-42 {
  padding-right: 42px !important;
}
.mesh-pl-42, .mesh-px-42 {
  padding-left: 42px !important;
}
.mesh-fs-42 {
  font-size: 42px;
}
.mesh-mb-43, .mesh-my-43 {
  margin-bottom: 43px !important;
}
.mesh-mt-43, .mesh-my-43 {
  margin-top: 43px !important;
}
.mesh-mr-43, .mesh-mx-43 {
  margin-right: 43px !important;
}
.mesh-ml-43, .mesh-mx-43 {
  margin-left: 43px !important;
}
.mesh-pb-43, .mesh-py-43 {
  padding-bottom: 43px !important;
}
.mesh-pt-43, .mesh-py-43 {
  padding-top: 43px !important;
}
.mesh-pr-43, .mesh-px-43 {
  padding-right: 43px !important;
}
.mesh-pl-43, .mesh-px-43 {
  padding-left: 43px !important;
}
.mesh-fs-43 {
  font-size: 43px;
}
.mesh-mb-44, .mesh-my-44 {
  margin-bottom: 44px !important;
}
.mesh-mt-44, .mesh-my-44 {
  margin-top: 44px !important;
}
.mesh-mr-44, .mesh-mx-44 {
  margin-right: 44px !important;
}
.mesh-ml-44, .mesh-mx-44 {
  margin-left: 44px !important;
}
.mesh-pb-44, .mesh-py-44 {
  padding-bottom: 44px !important;
}
.mesh-pt-44, .mesh-py-44 {
  padding-top: 44px !important;
}
.mesh-pr-44, .mesh-px-44 {
  padding-right: 44px !important;
}
.mesh-pl-44, .mesh-px-44 {
  padding-left: 44px !important;
}
.mesh-fs-44 {
  font-size: 44px;
}
.mesh-mb-45, .mesh-my-45 {
  margin-bottom: 45px !important;
}
.mesh-mt-45, .mesh-my-45 {
  margin-top: 45px !important;
}
.mesh-mr-45, .mesh-mx-45 {
  margin-right: 45px !important;
}
.mesh-ml-45, .mesh-mx-45 {
  margin-left: 45px !important;
}
.mesh-pb-45, .mesh-py-45 {
  padding-bottom: 45px !important;
}
.mesh-pt-45, .mesh-py-45 {
  padding-top: 45px !important;
}
.mesh-pr-45, .mesh-px-45 {
  padding-right: 45px !important;
}
.mesh-pl-45, .mesh-px-45 {
  padding-left: 45px !important;
}
.mesh-fs-45 {
  font-size: 45px;
}
.mesh-mb-46, .mesh-my-46 {
  margin-bottom: 46px !important;
}
.mesh-mt-46, .mesh-my-46 {
  margin-top: 46px !important;
}
.mesh-mr-46, .mesh-mx-46 {
  margin-right: 46px !important;
}
.mesh-ml-46, .mesh-mx-46 {
  margin-left: 46px !important;
}
.mesh-pb-46, .mesh-py-46 {
  padding-bottom: 46px !important;
}
.mesh-pt-46, .mesh-py-46 {
  padding-top: 46px !important;
}
.mesh-pr-46, .mesh-px-46 {
  padding-right: 46px !important;
}
.mesh-pl-46, .mesh-px-46 {
  padding-left: 46px !important;
}
.mesh-fs-46 {
  font-size: 46px;
}
.mesh-mb-47, .mesh-my-47 {
  margin-bottom: 47px !important;
}
.mesh-mt-47, .mesh-my-47 {
  margin-top: 47px !important;
}
.mesh-mr-47, .mesh-mx-47 {
  margin-right: 47px !important;
}
.mesh-ml-47, .mesh-mx-47 {
  margin-left: 47px !important;
}
.mesh-pb-47, .mesh-py-47 {
  padding-bottom: 47px !important;
}
.mesh-pt-47, .mesh-py-47 {
  padding-top: 47px !important;
}
.mesh-pr-47, .mesh-px-47 {
  padding-right: 47px !important;
}
.mesh-pl-47, .mesh-px-47 {
  padding-left: 47px !important;
}
.mesh-fs-47 {
  font-size: 47px;
}
.mesh-mb-48, .mesh-my-48 {
  margin-bottom: 48px !important;
}
.mesh-mt-48, .mesh-my-48 {
  margin-top: 48px !important;
}
.mesh-mr-48, .mesh-mx-48 {
  margin-right: 48px !important;
}
.mesh-ml-48, .mesh-mx-48 {
  margin-left: 48px !important;
}
.mesh-pb-48, .mesh-py-48 {
  padding-bottom: 48px !important;
}
.mesh-pt-48, .mesh-py-48 {
  padding-top: 48px !important;
}
.mesh-pr-48, .mesh-px-48 {
  padding-right: 48px !important;
}
.mesh-pl-48, .mesh-px-48 {
  padding-left: 48px !important;
}
.mesh-fs-48 {
  font-size: 48px;
}
.mesh-mb-49, .mesh-my-49 {
  margin-bottom: 49px !important;
}
.mesh-mt-49, .mesh-my-49 {
  margin-top: 49px !important;
}
.mesh-mr-49, .mesh-mx-49 {
  margin-right: 49px !important;
}
.mesh-ml-49, .mesh-mx-49 {
  margin-left: 49px !important;
}
.mesh-pb-49, .mesh-py-49 {
  padding-bottom: 49px !important;
}
.mesh-pt-49, .mesh-py-49 {
  padding-top: 49px !important;
}
.mesh-pr-49, .mesh-px-49 {
  padding-right: 49px !important;
}
.mesh-pl-49, .mesh-px-49 {
  padding-left: 49px !important;
}
.mesh-fs-49 {
  font-size: 49px;
}
.mesh-mb-50, .mesh-my-50 {
  margin-bottom: 50px !important;
}
.mesh-mt-50, .mesh-my-50 {
  margin-top: 50px !important;
}
.mesh-mr-50, .mesh-mx-50 {
  margin-right: 50px !important;
}
.mesh-ml-50, .mesh-mx-50 {
  margin-left: 50px !important;
}
.mesh-pb-50, .mesh-py-50 {
  padding-bottom: 50px !important;
}
.mesh-pt-50, .mesh-py-50 {
  padding-top: 50px !important;
}
.mesh-pr-50, .mesh-px-50 {
  padding-right: 50px !important;
}
.mesh-pl-50, .mesh-px-50 {
  padding-left: 50px !important;
}
.mesh-fs-50 {
  font-size: 50px;
}
.mesh-mb-51, .mesh-my-51 {
  margin-bottom: 51px !important;
}
.mesh-mt-51, .mesh-my-51 {
  margin-top: 51px !important;
}
.mesh-mr-51, .mesh-mx-51 {
  margin-right: 51px !important;
}
.mesh-ml-51, .mesh-mx-51 {
  margin-left: 51px !important;
}
.mesh-pb-51, .mesh-py-51 {
  padding-bottom: 51px !important;
}
.mesh-pt-51, .mesh-py-51 {
  padding-top: 51px !important;
}
.mesh-pr-51, .mesh-px-51 {
  padding-right: 51px !important;
}
.mesh-pl-51, .mesh-px-51 {
  padding-left: 51px !important;
}
.mesh-fs-51 {
  font-size: 51px;
}
.mesh-mb-52, .mesh-my-52 {
  margin-bottom: 52px !important;
}
.mesh-mt-52, .mesh-my-52 {
  margin-top: 52px !important;
}
.mesh-mr-52, .mesh-mx-52 {
  margin-right: 52px !important;
}
.mesh-ml-52, .mesh-mx-52 {
  margin-left: 52px !important;
}
.mesh-pb-52, .mesh-py-52 {
  padding-bottom: 52px !important;
}
.mesh-pt-52, .mesh-py-52 {
  padding-top: 52px !important;
}
.mesh-pr-52, .mesh-px-52 {
  padding-right: 52px !important;
}
.mesh-pl-52, .mesh-px-52 {
  padding-left: 52px !important;
}
.mesh-fs-52 {
  font-size: 52px;
}
.mesh-mb-53, .mesh-my-53 {
  margin-bottom: 53px !important;
}
.mesh-mt-53, .mesh-my-53 {
  margin-top: 53px !important;
}
.mesh-mr-53, .mesh-mx-53 {
  margin-right: 53px !important;
}
.mesh-ml-53, .mesh-mx-53 {
  margin-left: 53px !important;
}
.mesh-pb-53, .mesh-py-53 {
  padding-bottom: 53px !important;
}
.mesh-pt-53, .mesh-py-53 {
  padding-top: 53px !important;
}
.mesh-pr-53, .mesh-px-53 {
  padding-right: 53px !important;
}
.mesh-pl-53, .mesh-px-53 {
  padding-left: 53px !important;
}
.mesh-fs-53 {
  font-size: 53px;
}
.mesh-mb-54, .mesh-my-54 {
  margin-bottom: 54px !important;
}
.mesh-mt-54, .mesh-my-54 {
  margin-top: 54px !important;
}
.mesh-mr-54, .mesh-mx-54 {
  margin-right: 54px !important;
}
.mesh-ml-54, .mesh-mx-54 {
  margin-left: 54px !important;
}
.mesh-pb-54, .mesh-py-54 {
  padding-bottom: 54px !important;
}
.mesh-pt-54, .mesh-py-54 {
  padding-top: 54px !important;
}
.mesh-pr-54, .mesh-px-54 {
  padding-right: 54px !important;
}
.mesh-pl-54, .mesh-px-54 {
  padding-left: 54px !important;
}
.mesh-fs-54 {
  font-size: 54px;
}
.mesh-mb-55, .mesh-my-55 {
  margin-bottom: 55px !important;
}
.mesh-mt-55, .mesh-my-55 {
  margin-top: 55px !important;
}
.mesh-mr-55, .mesh-mx-55 {
  margin-right: 55px !important;
}
.mesh-ml-55, .mesh-mx-55 {
  margin-left: 55px !important;
}
.mesh-pb-55, .mesh-py-55 {
  padding-bottom: 55px !important;
}
.mesh-pt-55, .mesh-py-55 {
  padding-top: 55px !important;
}
.mesh-pr-55, .mesh-px-55 {
  padding-right: 55px !important;
}
.mesh-pl-55, .mesh-px-55 {
  padding-left: 55px !important;
}
.mesh-fs-55 {
  font-size: 55px;
}
.mesh-mb-56, .mesh-my-56 {
  margin-bottom: 56px !important;
}
.mesh-mt-56, .mesh-my-56 {
  margin-top: 56px !important;
}
.mesh-mr-56, .mesh-mx-56 {
  margin-right: 56px !important;
}
.mesh-ml-56, .mesh-mx-56 {
  margin-left: 56px !important;
}
.mesh-pb-56, .mesh-py-56 {
  padding-bottom: 56px !important;
}
.mesh-pt-56, .mesh-py-56 {
  padding-top: 56px !important;
}
.mesh-pr-56, .mesh-px-56 {
  padding-right: 56px !important;
}
.mesh-pl-56, .mesh-px-56 {
  padding-left: 56px !important;
}
.mesh-fs-56 {
  font-size: 56px;
}
.mesh-mb-57, .mesh-my-57 {
  margin-bottom: 57px !important;
}
.mesh-mt-57, .mesh-my-57 {
  margin-top: 57px !important;
}
.mesh-mr-57, .mesh-mx-57 {
  margin-right: 57px !important;
}
.mesh-ml-57, .mesh-mx-57 {
  margin-left: 57px !important;
}
.mesh-pb-57, .mesh-py-57 {
  padding-bottom: 57px !important;
}
.mesh-pt-57, .mesh-py-57 {
  padding-top: 57px !important;
}
.mesh-pr-57, .mesh-px-57 {
  padding-right: 57px !important;
}
.mesh-pl-57, .mesh-px-57 {
  padding-left: 57px !important;
}
.mesh-fs-57 {
  font-size: 57px;
}
.mesh-mb-58, .mesh-my-58 {
  margin-bottom: 58px !important;
}
.mesh-mt-58, .mesh-my-58 {
  margin-top: 58px !important;
}
.mesh-mr-58, .mesh-mx-58 {
  margin-right: 58px !important;
}
.mesh-ml-58, .mesh-mx-58 {
  margin-left: 58px !important;
}
.mesh-pb-58, .mesh-py-58 {
  padding-bottom: 58px !important;
}
.mesh-pt-58, .mesh-py-58 {
  padding-top: 58px !important;
}
.mesh-pr-58, .mesh-px-58 {
  padding-right: 58px !important;
}
.mesh-pl-58, .mesh-px-58 {
  padding-left: 58px !important;
}
.mesh-fs-58 {
  font-size: 58px;
}
.mesh-mb-59, .mesh-my-59 {
  margin-bottom: 59px !important;
}
.mesh-mt-59, .mesh-my-59 {
  margin-top: 59px !important;
}
.mesh-mr-59, .mesh-mx-59 {
  margin-right: 59px !important;
}
.mesh-ml-59, .mesh-mx-59 {
  margin-left: 59px !important;
}
.mesh-pb-59, .mesh-py-59 {
  padding-bottom: 59px !important;
}
.mesh-pt-59, .mesh-py-59 {
  padding-top: 59px !important;
}
.mesh-pr-59, .mesh-px-59 {
  padding-right: 59px !important;
}
.mesh-pl-59, .mesh-px-59 {
  padding-left: 59px !important;
}
.mesh-fs-59 {
  font-size: 59px;
}
.mesh-mb-60, .mesh-my-60 {
  margin-bottom: 60px !important;
}
.mesh-mt-60, .mesh-my-60 {
  margin-top: 60px !important;
}
.mesh-mr-60, .mesh-mx-60 {
  margin-right: 60px !important;
}
.mesh-ml-60, .mesh-mx-60 {
  margin-left: 60px !important;
}
.mesh-pb-60, .mesh-py-60 {
  padding-bottom: 60px !important;
}
.mesh-pt-60, .mesh-py-60 {
  padding-top: 60px !important;
}
.mesh-pr-60, .mesh-px-60 {
  padding-right: 60px !important;
}
.mesh-pl-60, .mesh-px-60 {
  padding-left: 60px !important;
}
.mesh-fs-60 {
  font-size: 60px;
}
.mesh-mb-61, .mesh-my-61 {
  margin-bottom: 61px !important;
}
.mesh-mt-61, .mesh-my-61 {
  margin-top: 61px !important;
}
.mesh-mr-61, .mesh-mx-61 {
  margin-right: 61px !important;
}
.mesh-ml-61, .mesh-mx-61 {
  margin-left: 61px !important;
}
.mesh-pb-61, .mesh-py-61 {
  padding-bottom: 61px !important;
}
.mesh-pt-61, .mesh-py-61 {
  padding-top: 61px !important;
}
.mesh-pr-61, .mesh-px-61 {
  padding-right: 61px !important;
}
.mesh-pl-61, .mesh-px-61 {
  padding-left: 61px !important;
}
.mesh-fs-61 {
  font-size: 61px;
}
.mesh-mb-62, .mesh-my-62 {
  margin-bottom: 62px !important;
}
.mesh-mt-62, .mesh-my-62 {
  margin-top: 62px !important;
}
.mesh-mr-62, .mesh-mx-62 {
  margin-right: 62px !important;
}
.mesh-ml-62, .mesh-mx-62 {
  margin-left: 62px !important;
}
.mesh-pb-62, .mesh-py-62 {
  padding-bottom: 62px !important;
}
.mesh-pt-62, .mesh-py-62 {
  padding-top: 62px !important;
}
.mesh-pr-62, .mesh-px-62 {
  padding-right: 62px !important;
}
.mesh-pl-62, .mesh-px-62 {
  padding-left: 62px !important;
}
.mesh-fs-62 {
  font-size: 62px;
}
.mesh-mb-63, .mesh-my-63 {
  margin-bottom: 63px !important;
}
.mesh-mt-63, .mesh-my-63 {
  margin-top: 63px !important;
}
.mesh-mr-63, .mesh-mx-63 {
  margin-right: 63px !important;
}
.mesh-ml-63, .mesh-mx-63 {
  margin-left: 63px !important;
}
.mesh-pb-63, .mesh-py-63 {
  padding-bottom: 63px !important;
}
.mesh-pt-63, .mesh-py-63 {
  padding-top: 63px !important;
}
.mesh-pr-63, .mesh-px-63 {
  padding-right: 63px !important;
}
.mesh-pl-63, .mesh-px-63 {
  padding-left: 63px !important;
}
.mesh-fs-63 {
  font-size: 63px;
}
.mesh-mb-64, .mesh-my-64 {
  margin-bottom: 64px !important;
}
.mesh-mt-64, .mesh-my-64 {
  margin-top: 64px !important;
}
.mesh-mr-64, .mesh-mx-64 {
  margin-right: 64px !important;
}
.mesh-ml-64, .mesh-mx-64 {
  margin-left: 64px !important;
}
.mesh-pb-64, .mesh-py-64 {
  padding-bottom: 64px !important;
}
.mesh-pt-64, .mesh-py-64 {
  padding-top: 64px !important;
}
.mesh-pr-64, .mesh-px-64 {
  padding-right: 64px !important;
}
.mesh-pl-64, .mesh-px-64 {
  padding-left: 64px !important;
}
.mesh-fs-64 {
  font-size: 64px;
}
.mesh-mb-65, .mesh-my-65 {
  margin-bottom: 65px !important;
}
.mesh-mt-65, .mesh-my-65 {
  margin-top: 65px !important;
}
.mesh-mr-65, .mesh-mx-65 {
  margin-right: 65px !important;
}
.mesh-ml-65, .mesh-mx-65 {
  margin-left: 65px !important;
}
.mesh-pb-65, .mesh-py-65 {
  padding-bottom: 65px !important;
}
.mesh-pt-65, .mesh-py-65 {
  padding-top: 65px !important;
}
.mesh-pr-65, .mesh-px-65 {
  padding-right: 65px !important;
}
.mesh-pl-65, .mesh-px-65 {
  padding-left: 65px !important;
}
.mesh-fs-65 {
  font-size: 65px;
}
.mesh-mb-66, .mesh-my-66 {
  margin-bottom: 66px !important;
}
.mesh-mt-66, .mesh-my-66 {
  margin-top: 66px !important;
}
.mesh-mr-66, .mesh-mx-66 {
  margin-right: 66px !important;
}
.mesh-ml-66, .mesh-mx-66 {
  margin-left: 66px !important;
}
.mesh-pb-66, .mesh-py-66 {
  padding-bottom: 66px !important;
}
.mesh-pt-66, .mesh-py-66 {
  padding-top: 66px !important;
}
.mesh-pr-66, .mesh-px-66 {
  padding-right: 66px !important;
}
.mesh-pl-66, .mesh-px-66 {
  padding-left: 66px !important;
}
.mesh-fs-66 {
  font-size: 66px;
}
.mesh-mb-67, .mesh-my-67 {
  margin-bottom: 67px !important;
}
.mesh-mt-67, .mesh-my-67 {
  margin-top: 67px !important;
}
.mesh-mr-67, .mesh-mx-67 {
  margin-right: 67px !important;
}
.mesh-ml-67, .mesh-mx-67 {
  margin-left: 67px !important;
}
.mesh-pb-67, .mesh-py-67 {
  padding-bottom: 67px !important;
}
.mesh-pt-67, .mesh-py-67 {
  padding-top: 67px !important;
}
.mesh-pr-67, .mesh-px-67 {
  padding-right: 67px !important;
}
.mesh-pl-67, .mesh-px-67 {
  padding-left: 67px !important;
}
.mesh-fs-67 {
  font-size: 67px;
}
.mesh-mb-68, .mesh-my-68 {
  margin-bottom: 68px !important;
}
.mesh-mt-68, .mesh-my-68 {
  margin-top: 68px !important;
}
.mesh-mr-68, .mesh-mx-68 {
  margin-right: 68px !important;
}
.mesh-ml-68, .mesh-mx-68 {
  margin-left: 68px !important;
}
.mesh-pb-68, .mesh-py-68 {
  padding-bottom: 68px !important;
}
.mesh-pt-68, .mesh-py-68 {
  padding-top: 68px !important;
}
.mesh-pr-68, .mesh-px-68 {
  padding-right: 68px !important;
}
.mesh-pl-68, .mesh-px-68 {
  padding-left: 68px !important;
}
.mesh-fs-68 {
  font-size: 68px;
}
.mesh-mb-69, .mesh-my-69 {
  margin-bottom: 69px !important;
}
.mesh-mt-69, .mesh-my-69 {
  margin-top: 69px !important;
}
.mesh-mr-69, .mesh-mx-69 {
  margin-right: 69px !important;
}
.mesh-ml-69, .mesh-mx-69 {
  margin-left: 69px !important;
}
.mesh-pb-69, .mesh-py-69 {
  padding-bottom: 69px !important;
}
.mesh-pt-69, .mesh-py-69 {
  padding-top: 69px !important;
}
.mesh-pr-69, .mesh-px-69 {
  padding-right: 69px !important;
}
.mesh-pl-69, .mesh-px-69 {
  padding-left: 69px !important;
}
.mesh-fs-69 {
  font-size: 69px;
}
.mesh-mb-70, .mesh-my-70 {
  margin-bottom: 70px !important;
}
.mesh-mt-70, .mesh-my-70 {
  margin-top: 70px !important;
}
.mesh-mr-70, .mesh-mx-70 {
  margin-right: 70px !important;
}
.mesh-ml-70, .mesh-mx-70 {
  margin-left: 70px !important;
}
.mesh-pb-70, .mesh-py-70 {
  padding-bottom: 70px !important;
}
.mesh-pt-70, .mesh-py-70 {
  padding-top: 70px !important;
}
.mesh-pr-70, .mesh-px-70 {
  padding-right: 70px !important;
}
.mesh-pl-70, .mesh-px-70 {
  padding-left: 70px !important;
}
.mesh-fs-70 {
  font-size: 70px;
}

.theme-app .bs-datepicker-container {
  padding: 0;
}
.theme-app .bs-datepicker-head {
  background-color: #00D0B3;
}
.theme-app .bs-datepicker-head bs-datepicker-navigation-view {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.theme-app .bs-datepicker-head button {
  color: #ffffff;
  text-transform: capitalize;
}
.theme-app .bs-datepicker-head button.next span, .theme-app .bs-datepicker-head button.previous span {
  font-size: 26px;
}
.theme-app .bs-datepicker-predefined-btns button.selected {
  background-color: #00D0B3;
  color: #ffffff;
}
.theme-app .bs-datepicker-body table.days span {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.theme-app .bs-datepicker-body table th {
  color: #8D949D;
}
.theme-app .bs-datepicker-body table td {
  color: #000000;
}
.theme-app .bs-datepicker-body table td span.selected {
  background-color: #00D0B3;
  color: #ffffff;
}
.theme-app .bs-datepicker-body table td span.disabled {
  color: #BBCBD7;
  cursor: auto;
}
.theme-app .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #00D0B3;
}
.theme-app .bs-datepicker-body table td.selected span {
  background-color: #00D0B3;
}
.theme-app .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #00D0B3;
}
.theme-app .bs-datepicker-body table td.week span {
  color: #00D0B3;
}
.theme-app .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #00D0B3;
  color: #FEFEFE;
  opacity: 0.5;
  transition: 0s;
}

.open-basket,
.modal-open {
  overflow: hidden !important;
}

.modal .modal-dialog.modal-notify {
  max-width: 400px !important;
}
@media (min-width: 576px) {
  .modal .modal-dialog.modal-notify {
    max-width: 400px;
  }
}
.modal .modal-dialog.modal-notify .modal-content {
  border-radius: 10px;
}
.modal .modal-dialog.modal-notify.basket-error {
  margin-right: 40px;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
}
@media (max-width: 800px) {
  .modal .modal-dialog.modal-notify.basket-error {
    margin: 0 auto;
  }
}
.modal .modal-dialog.modal-notify-small {
  max-width: 340px !important;
}
@media (min-width: 576px) {
  .modal .modal-dialog.modal-notify-small {
    max-width: 340px;
  }
}
.modal .modal-dialog.modal-notify-small .modal-content {
  border-radius: 10px;
}
@media (max-width: 420px) {
  .modal .modal-dialog.modal-notify-small {
    max-width: unset !important;
    margin: 0;
    align-items: flex-end;
    min-height: 100%;
  }
  .modal .modal-dialog.modal-notify-small .modal-content {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 0px 15px 0px rgba(18, 16, 104, 0.15);
  }
}
.modal .modal-dialog.modal-dialog-right {
  margin-right: 0;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .modal .modal-dialog.modal-dialog-right {
    max-width: 100% !important;
    width: 100% !important;
  }
}
.modal .modal-dialog.modal-dialog-right .modal-content {
  border: none;
}
@media (min-width: 768px) {
  .modal .modal-dialog.modal-dialog-md {
    max-width: 760px;
    width: 760px;
  }
}
.modal .modal-dialog .icon-closed {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z' fill='%23878F9F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: background-position 150ms ease, opacity 60ms linear;
  width: 16px;
  height: 16px;
}

.iql-loading-parent--relative {
  position: relative !important;
}

.iql-loading-parent--hidden {
  overflow: hidden !important;
}

.iql-loading-mask {
  position: absolute;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.iql-loading-mask.is-fullscreen {
  position: fixed;
}
.iql-loading-mask.is-fullscreen .iql-loading-spinner {
  margin-top: -25px;
}
.iql-loading-mask.is-fullscreen .iql-loading-spinner .circular {
  height: 50px;
  width: 50px;
}

.iql-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}
.iql-loading-spinner .iql-loading-text {
  color: #000000;
  margin: 3px 0;
  font-size: 14px;
}
.iql-loading-spinner .circular {
  height: 50px;
  width: 50px;
  -webkit-animation: loading-rotate 2s linear infinite;
  animation: loading-rotate 2s linear infinite;
}
.iql-loading-spinner .path {
  -webkit-animation: loading-dash 1.5s ease-in-out infinite;
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #19AD79;
  stroke-linecap: round;
}
.iql-loading-spinner i {
  color: #19AD79;
}

.iql-loading-fade-enter,
.iql-loading-fade-leave-active {
  opacity: 0;
}

@-webkit-keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
.toast-container .toast {
  opacity: 1;
  border-radius: 10px;
}

.toast-success {
  background-color: #00D0B3;
}

.toast-error {
  background-color: #FF5254;
  box-shadow: 0px 0px 10px rgba(255, 82, 84, 0.25);
}

.toast-error {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='30' rx='15' fill='%23CF2F3C'/%3E%3Cpath d='M13.5 23.5C13.5 22.6716 14.1716 22 15 22C15.8284 22 16.5 22.6716 16.5 23.5C16.5 24.3284 15.8284 25 15 25C14.1716 25 13.5 24.3284 13.5 23.5ZM15 5C14.1716 5 13.5 5.67157 13.5 6.5V18.5C13.5 19.3284 14.1716 20 15 20C15.8284 20 16.5 19.3284 16.5 18.5V6.5C16.5 5.67157 15.8284 5 15 5Z' fill='white'/%3E%3C/svg%3E%0A");
}

.custom-table-header {
  background: #F4F7FA;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 20px;
  border-bottom: 1px solid #ECEEF1;
  gap: 20px;
}

.custom-table-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #889099;
  padding: 13px 0;
  padding-right: 20px;
  min-width: 140px;
}
.custom-table-cell.id {
  max-width: 30px;
  min-width: 30px;
}
.custom-table-cell.photo {
  max-width: 100px;
  min-width: 100px;
}
.custom-table-cell.select {
  max-width: 18px;
  min-width: 18px;
}
.custom-table-cell.status {
  min-width: 60px;
  max-width: 60px;
}
.custom-table-cell.actions {
  justify-content: flex-end;
  padding-right: 0;
  z-index: 1;
}
.custom-table-cell .btn-sort {
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  padding: 0;
  padding-right: 5px;
  border: none;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #889099;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
}
.custom-table-cell .btn-sort:focus {
  outline: none;
}
.custom-table-cell .btn-sort::after {
  content: "";
  position: absolute;
  right: -15px;
  top: -1px;
  display: inline-block;
  flex: none;
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%23889099'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%23889099'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
}
.custom-table-cell .btn-sort.active {
  color: #00D0B3;
}
.custom-table-cell .btn-sort.active::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%2300D0B3'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%2300D0B3'/%3E%3C/svg%3E%0A");
}
.custom-table-cell .btn-sort.sort-asc::after {
  transform: rotate(-180deg);
}
.custom-table-cell .btn-sort.sort-desc::after {
  transform: rotate(0);
}
.custom-table-cell .btn-sort.not-sort::after {
  content: none;
}

.custom-table-item-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ECEEF1;
  padding: 0px 20px;
  gap: 20px;
}
.custom-table-item-container .custom-table-cell {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  height: auto;
  padding: 0;
  padding-right: 20px;
}
.custom-table-item-container .custom-table-cell.actions {
  padding-right: 0;
  z-index: 1;
}

.custom-table-body {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
}
.custom-table-body .empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI-Light.eot");
  src: local("Segoe UI Light"), local("SegoeUI-Light"), url("/assets/fonts/SegoeUI/SegoeUI-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI-Light.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot");
  src: local("Segoe UI Bold Italic"), local("SegoeUI-BoldItalic"), url("/assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI-BoldItalic.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI-SemiBold.eot");
  src: local("Segoe UI Semibold"), local("SegoeUI-SemiBold"), url("/assets/fonts/SegoeUI/SegoeUI-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI-SemiBold.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI.eot");
  src: local("Segoe UI"), local("SegoeUI"), url("/assets/fonts/SegoeUI/SegoeUI.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI-Bold.eot");
  src: local("Segoe UI Bold"), local("SegoeUI-Bold"), url("/assets/fonts/SegoeUI/SegoeUI-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI-Bold.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/SegoeUI/SegoeUI-Italic.eot");
  src: local("Segoe UI Italic"), local("SegoeUI-Italic"), url("/assets/fonts/SegoeUI/SegoeUI-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SegoeUI/SegoeUI-Italic.woff") format("woff"), url("/assets/fonts/SegoeUI/SegoeUI-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "SF Text Regular";
  src: url("/assets/fonts/SF/SFUIText-Regular.eot");
  src: url("/assets/fonts/SF/SFUIText-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFUIText-Regular.woff") format("woff"), url("/assets/fonts/SF/SFUIText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-Medium.eot");
  src: local("SF Pro Display Medium"), local("SFProDisplay-Medium"), url("/assets/fonts/SF/SFProDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-Medium.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-Medium.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-RegularItalic.eot");
  src: local("SF Pro Display Regular Italic"), local("SFProDisplay-RegularItalic"), url("/assets/fonts/SF/SFProDisplay-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-RegularItalic.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-RegularItalic.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-Bold.eot");
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"), url("/assets/fonts/SF/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-Bold.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-Bold.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-Semibold.eot");
  src: local("SF Pro Display Semibold"), local("SFProDisplay-Semibold"), url("/assets/fonts/SF/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-Semibold.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-Semibold.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-Regular.eot");
  src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"), url("/assets/fonts/SF/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-Regular.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-Regular.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/SF/SFProDisplay-BoldItalic.eot");
  src: local("SF Pro Display BoldItalic"), local("SFProDisplay-BoldItalic"), url("/assets/fonts/SF/SFProDisplay-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProDisplay-BoldItalic.woff2") format("woff2"), url("/assets/fonts/SF/SFProDisplay-BoldItalic.woff") format("woff"), url("/assets/fonts/SF/SFProDisplay-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/assets/fonts/SF/SFProText/SFProText-Regular.eot");
  src: local("SF Pro Text Regular"), local("SFProText-Regular"), url("/assets/fonts/SF/SFProText/SFProText-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProText/SFProText-Regular.woff2") format("woff2"), url("/assets/fonts/SF/SFProText/SFProText-Regular.woff") format("woff"), url("/assets/fonts/SF/SFProText/SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/assets/fonts/SF/SFProText/SFProText-Semibold.eot");
  src: local("SF Pro Text Semibold"), local("SFProText-Semibold"), url("/assets/fonts/SF/SFProText/SFProText-Semibold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProText/SFProText-Semibold.woff2") format("woff2"), url("/assets/fonts/SF/SFProText/SFProText-Semibold.woff") format("woff"), url("/assets/fonts/SF/SFProText/SFProText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/assets/fonts/SF/SFProText/SFProText-Light.eot");
  src: local("SF Pro Text Light"), local("SFProText-Light"), url("/assets/fonts/SF/SFProText/SFProText-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/SF/SFProText/SFProText-Light.woff2") format("woff2"), url("/assets/fonts/SF/SFProText/SFProText-Light.woff") format("woff"), url("/assets/fonts/SF/SFProText/SFProText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
::selection {
  background: #00D0B3;
  color: #FFFFFF;
}

.fluid-bread {
  background-color: transparent !important;
}

.question-eq {
  position: relative;
  width: 100%;
}

.question-eq .question-eq-list {
  position: relative;
  z-index: 2;
}

.question-eq .question-eq-list__item {
  border: 0;
  padding: 5px;
  margin-bottom: 10px;
  font-family: SF Pro Display, sans-serif;
  text-overflow: ellipsis;
  display: flex;
}

.question-eq .question-eq-list__item__answer {
  padding: 5px;
  width: 50%;
  margin: 5px;
  opacity: 0;
}

.question-eq .question-eq-list__item__equal {
  width: 50%;
}

.question-eq .question-eq-list__item__equal > div {
  padding: 5px;
  margin: 5px;
  border: 1px solid #10cfbd;
}

.question-eq .question-eq-list--ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.question-eq .question-eq-list--ghost__item {
  border-bottom: 1px solid #CCC;
}

.question-eq .question-eq-list--ghost__item__answer {
  opacity: 1;
  border: 1px solid #10cfbd;
}

.question-eq .question-eq-list--ghost__item__equal {
  opacity: 0;
}

.question-eq .question-eq-list__item.draggable .question-eq-list__item__answer {
  opacity: 1;
}

.btn-standard,
.btn-standard:hover {
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  font-size: 17px;
  line-height: 23px;
  position: relative;
  padding: 5px 10px;
  box-shadow: unset;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-standard.green,
.btn-standard:hover.green {
  background-color: #00D0B3;
}
.btn-standard.green-outline,
.btn-standard:hover.green-outline {
  background: #FFFFFF;
  border: 1px solid #00D0B3;
  color: #00D0B3;
}
.btn-standard.blue,
.btn-standard:hover.blue {
  background: #0EBCF2;
}

.flex-fluid {
  flex: 1 1 10px;
}

.start-button {
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font: 700 16px/36px Circe, Arial, sans-serif;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  position: relative;
  margin-top: 10px;
  height: 36px;
  width: 259px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #10cfbd;
  -webkit-box-shadow: 0 18px 50px 0 rgba(16, 207, 189, 0.32);
  box-shadow: 0 18px 50px 0 rgba(16, 207, 189, 0.32);
}

/*!
 * FullCalendar v3.1.0 Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2016 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left;
}

.fc-rtl {
  text-align: right;
}

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #ddd;
}

.fc-unthemed .fc-popover {
  background-color: #FFFFFF;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
  background: #E3E3E3;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666666;
}

.fc-unthemed .fc-today {
  background: #F5F5F5;
}

.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
}

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
}

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative;
  margin: 0 -1em;
  /* ensures character will be centered, regardless of width */
}

.fc-icon-left-single-arrow:after {
  content: "‹";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: 3%;
}

.fc-icon-right-single-arrow:after {
  content: "›";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: -3%;
}

.fc-icon-left-double-arrow:after {
  content: "«";
  font-size: 160%;
  top: -7%;
}

.fc-icon-right-double-arrow:after {
  content: "»";
  font-size: 160%;
  top: -7%;
}

.fc-icon-left-triangle:after {
  content: "◄";
  font-size: 125%;
  top: 3%;
  left: -2%;
}

.fc-icon-right-triangle:after {
  content: "►";
  font-size: 125%;
  top: 3%;
  left: 2%;
}

.fc-icon-down-triangle:after {
  content: "▼";
  font-size: 125%;
  top: 2%;
}

.fc-icon-x:after {
  content: "×";
  font-size: 200%;
  top: 6%;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  border-radius: 4px !important;
  box-sizing: border-box;
  border-width: 1px;
  background-color: transparent;
  font-size: 9px;
  font-weight: 500;
  outline: none;
  padding: 5px;
  border-color: #939393;
  color: #2C2C2C;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-right: 5px;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc button:hover,
.fc button:focus,
.fc button:active,
.fc button.active,
.fc button:active:focus,
.fc button:active:hover,
.open > .fc button.dropdown-toggle,
.open > .fc button.dropdown-toggle:focus,
.open > .fc button.dropdown-toggle:hover {
  background-color: #66615B;
  color: rgba(255, 255, 255, 0.85);
  border-color: #66615B;
}

.fc button:hover .caret,
.fc button:focus .caret,
.fc button:active .caret,
.fc button.active .caret,
.fc button:active:focus .caret,
.fc button:active:hover .caret,
.open > .fc button.dropdown-toggle .caret,
.open > .fc button.dropdown-toggle:focus .caret,
.open > .fc button.dropdown-toggle:hover .caret {
  border-top-color: rgba(255, 255, 255, 0.85);
}

.fc button.disabled,
.fc button.disabled:hover,
.fc button.disabled:focus,
.fc button.disabled.focus,
.fc button.disabled:active,
.fc button.disabled.active,
.fc button:disabled,
.fc button:disabled:hover,
.fc button:disabled:focus,
.fc button:disabled.focus,
.fc button:disabled:active,
.fc button:disabled.active,
.fc button[disabled],
.fc button[disabled]:hover,
.fc button[disabled]:focus,
.fc button[disabled].focus,
.fc button[disabled]:active,
.fc button[disabled].active,
fieldset[disabled] .fc button,
fieldset[disabled] .fc button:hover,
fieldset[disabled] .fc button:focus,
fieldset[disabled] .fc button.focus,
fieldset[disabled] .fc button:active,
fieldset[disabled] .fc button.active {
  background-color: transparent;
  border-color: #66615B;
}

.fc button.btn-fill {
  color: #FFFFFF;
  background-color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100);
}

.fc button.btn-fill:hover,
.fc button.btn-fill:focus,
.fc button.btn-fill:active,
.fc button.btn-fill.active,
.open > .fc button.btn-fill.dropdown-toggle {
  background-color: #484541;
  color: #FFFFFF;
  border-color: #484541;
}

.fc button.btn-fill .caret {
  border-top-color: #FFFFFF;
}

.fc button.btn-simple:hover,
.fc button.btn-simple:focus,
.fc button.btn-simple:active,
.fc button.btn-simple.active,
.open > .fc button.btn-simple.dropdown-toggle {
  background-color: transparent;
  color: #484541;
}

.fc button.btn-simple .caret {
  border-top-color: #FFFFFF;
}

.fc button .caret {
  border-top-color: #66615B;
}

.fc button:hover,
.fc button:focus {
  outline: 0 !important;
}

.fc button:active,
.fc button.active,
.open > .fc button.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}

.fc button.btn-icon {
  border-radius: 25px;
  padding: 6px 10px;
}

.fc button.btn-icon i {
  margin-right: 0px;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}

.fc-state-default {
  /* non-theme */
  border: 1px solid;
}

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 0.2em;
  vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-hover,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}

.fc button[disabled],
.fc button[disabled]:focus,
.fc button[disabled]:hover {
  background-color: #E3E3E3;
  border-color: #E3E3E3;
  cursor: default;
  opacity: 0.65;
  filter: alpha(opacity=65);
  color: #66615b;
}

.fc-state-hover {
  background-color: #F5F5F5;
}

.fc .fc-state-active,
.fc .fc-state-active:focus,
.fc .fc-state-active:hover,
.fc .fc-state-active:active:focus,
.fc .fc-state-active:active:hover,
.fc .fc-state-active:active {
  background-color: #66615B;
  color: #FFFFFF;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 5px 0 0;
}

.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
}

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
  cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}

/* unthemed */
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: 0.9em;
  padding-right: 5px;
  margin-top: 0;
}
.fc-unthemed .fc-popover .fc-header .fc-close::after {
  top: 0;
}

/* jqui themed */
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px;
}

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}

.fc-clear {
  clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */
}

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */
}

.fc th {
  text-align: right;
  border-bottom-width: 1px;
  font-size: 0.9em;
  text-transform: uppercase;
  color: #9A9A9A;
  font-weight: 400;
  padding-bottom: 3px;
}

.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */
}

.fc .fc-widget-header {
  border: 0;
}

.fc .fc-head .fc-widget-header {
  margin-top: 15px;
}

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}

.fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative;
}

.fc-row .fc-bg {
  z-index: 1;
}

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}

.fc-row .fc-highlight-skeleton {
  z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */
}

.fc-row .fc-helper-skeleton {
  z-index: 5;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent;
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  /* this class goes on elements for guaranteed vertical scrollbars */
  overflow-y: scroll;
  overflow-x: hidden;
}

.fc-scroller > * {
  /* we expect an immediate inner element */
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */
  overflow: hidden;
  /* don't let negative margins or absolute positioning create further scroll */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: 0.85em;
  line-height: 1.3;
  border-radius: 2px;
  background-color: rgba(122, 158, 159, 0.2);
  /* default BACKGROUND color */
  font-weight: normal;
  /* undo jqui's ui-widget-header bold */
}

.fc-event.event-azure {
  background-color: rgba(104, 179, 200, 0.2);
}

.fc-event.event-green {
  background-color: rgba(122, 194, 154, 0.2);
}

.fc-event.event-orange {
  background-color: rgba(243, 187, 69, 0.2);
}

.fc-event.event-red {
  background-color: rgba(235, 94, 40, 0.2);
}

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #333333;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed;
}

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #FFFFFF;
  opacity: 0.25;
  filter: alpha(opacity=25);
  /* for IE */
}

.fc-event .fc-content {
  position: relative;
  z-index: 2;
}

.fc-event .fc-resizer {
  position: absolute;
  z-index: 3;
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* resizer */
.fc-h-event .fc-resizer {
  /* positioned it to overcome the event's borders */
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: 5px;
}

/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-ltr .fc-h-event .fc-start-resizer:before,
.fc-ltr .fc-h-event .fc-start-resizer:after,
.fc-rtl .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-end-resizer:before,
.fc-rtl .fc-h-event .fc-end-resizer:after {
  right: auto;
  /* ignore the right and only use the left */
  cursor: w-resize;
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-ltr .fc-h-event .fc-end-resizer:before,
.fc-ltr .fc-h-event .fc-end-resizer:after,
.fc-rtl .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-start-resizer:before,
.fc-rtl .fc-h-event .fc-start-resizer:after {
  left: auto;
  /* ignore the left and only use the right */
  cursor: e-resize;
}

.fc-day-number {
  float: left !important;
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
a.fc-day-grid-event,
a.fc-more {
  margin: 2px 0 0 2px;
  border: 0;
  white-space: nowrap;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* spacing between events and edges */
  padding: 4px 0 4px 7px;
}
a.fc-day-grid-event.fc-event.event-status,
a.fc-more.fc-event.event-status {
  font-size: 13px;
  background-color: #10CFBD;
  color: white;
}
a.fc-day-grid-event.fc-event.event-status.event-status-take,
a.fc-more.fc-event.event-status.event-status-take {
  background-color: #48B0F7;
}

@media (min-width: 768px) {
  .fc-day-top,
.fc-day-number {
    height: 1px;
  }

  a.fc-day-grid-event,
a.fc-more {
    margin-left: 20px;
  }
}
.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-day-grid-event .fc-time {
  font-weight: bold;
}

.fc-day-grid-event .fc-resizer {
  /* enlarge the default hit area */
  left: -3px;
  right: -3px;
  width: 7px;
}

.fc-more-cell {
  text-align: right;
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: underline !important;
}

a.fc-more:hover {
  text-decoration: none !important;
}

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none;
}

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */
}

.fc-more-popover {
  z-index: 2;
  width: 220px;
}

.fc-more-popover .fc-event-container {
  padding: 10px;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  flex-direction: row-reverse;
  justify-content: flex-end;
  text-align: center;
  margin-bottom: 1em;
}

.fc-toolbar .fc-left {
  float: left;
  min-width: 150px;
}

.small-calendar .fc-dayGrid-view .fc-body .fc-row {
  min-height: 2em;
}

.fc-toolbar .fc-right {
  float: right;
  padding-right: 20px;
}

.fc-toolbar .fc-center {
  display: inline-block;
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: 0.75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0;
}

/* title text */
.fc-toolbar h2 {
  margin: 0;
  font-size: 15px;
}

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}

.fc-toolbar .fc-state-down {
  z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}

.fc-toolbar button:focus {
  z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fc-view,
.fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* we are sure there are no day numbers in these views, so... */
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */
}

.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* week and day number styling */
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 0 2px;
}

.fc-basic-view td.fc-week-number span,
.fc-basic-view td.fc-day-number {
  padding: 8px;
}

.fc-basic-view .fc-week-number {
  text-align: center;
}

.fc-basic-view .fc-week-number span {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: right;
}

.fc-rtl .fc-basic-view .fc-day-number {
  text-align: left;
}

.fc-other-month {
  background-color: #F3F6FA;
  color: #97A3B4;
}

.fc-day-number {
  color: #AAB2C0;
}

.fc-day-number.fc-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
  /* opacity with small font can sometimes look too faded
     might want to set the 'color' property instead
     making day-numbers bold also fixes the problem */
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}

.fc-ltr .fc-axis {
  text-align: right;
}

.fc-rtl .fc-axis {
  text-align: left;
}

.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome jqui theme making it bold */
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
  z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-time-grid .fc-bgevent-skeleton {
  z-index: 3;
}

.fc-time-grid .fc-highlight-skeleton {
  z-index: 4;
}

.fc-time-grid .fc-content-skeleton {
  z-index: 5;
}

.fc-time-grid .fc-helper-skeleton {
  z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}

.fc-time-grid .fc-slats .ui-widget-content {
  /* for jqui theme */
  background: none;
  /* see through to fc-bg */
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  /* a div within a cell within the fc-bgevent-skeleton */
  position: relative;
}

.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}

.fc-time-grid-event .fc-time {
  font-size: 0.85em;
  white-space: nowrap;
}

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: " - ";
  /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: 0.85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */
}

/* resizer */
.fc-time-grid-event .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-time-grid-event .fc-resizer:after {
  content: "=";
}

.card-calendar .content {
  padding: 0 !important;
}

.card-calendar .fc-toolbar {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.card-calendar .fc td:first-child {
  border-left: 0;
}

.card-calendar .fc td:last-child {
  border-right: 0;
}

.card-calendar .fc-basic-view td:last-child.fc-week-number span,
.card-calendar .fc-basic-view td:last-child.fc-day-number {
  padding-right: 20px;
}

.card-calendar .fc .fc-day-header:last-child {
  padding-right: 15px;
}

.card-calendar .fc .fc-row:last-child td {
  border-bottom: 0;
}

.card-calendar .fc .fc-body .fc-widget-content {
  border-bottom: 0;
}

.small-calendar {
  border-top: 1px solid #e6e6e6;
  padding: 10px;
  background: #FFF;
}

.page-widget {
  margin-bottom: 10px;
}
.page-widget .rating-widget .tab-content .tab-pane {
  padding-bottom: 0px !important;
}
.page-widget pg-tab-body .row {
  max-height: 400px !important;
  overflow: auto !important;
}

.corner-badge {
  position: absolute;
  height: 80px;
  width: 80px;
  top: -40px;
  right: -40px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  border-radius: 0;
  color: white;
  font-size: 13px;
}
.corner-badge span {
  align-self: flex-end;
}
.corner-badge.new {
  background-color: #27cebc;
  z-index: 2;
}
.corner-badge.hit {
  background-color: #f7554e;
  z-index: 3;
}
.corner-badge.recommend {
  background-color: #41aef9;
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

body#body .ngx-position-absolute.ngx-overlay {
  z-index: 500 !important;
}

.dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a {
  color: #626262 !important;
}
.dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a::after, .dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a:hover::after, .dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a:hover, .dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a.collapsed::after, .dashboard-collapse.gray-title pg-collapse.card.card-default > .card-header > h4 > a.collapced:hover::after {
  color: #626262 !important;
}
.dashboard-collapse.full-height > pg-collapseset > .card-group {
  height: 100%;
}
.dashboard-collapse.full-height > pg-collapseset > .card-group > pg-collapse {
  display: flex;
}
.dashboard-collapse.full-height > pg-collapseset > .card-group > pg-collapse .collapse {
  flex-grow: 1;
}
.dashboard-collapse.full-height > pg-collapseset > .card-group > pg-collapse .collapse > .card-body {
  height: 100%;
}
.dashboard-collapse pg-collapse.card.card-default,
.dashboard-collapse .pg-collapse.card.card-default {
  border: none;
  background: none;
}
.dashboard-collapse pg-collapse.card.card-default > .collapse > .card-body,
.dashboard-collapse .pg-collapse.card.card-default > .collapse > .card-body {
  padding: 0;
}
.dashboard-collapse pg-collapse.card.card-default > .card-header,
.dashboard-collapse .pg-collapse.card.card-default > .card-header {
  margin-bottom: 0;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
}
.dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a {
  display: inline-block;
  position: relative;
  color: #00D0B3 !important;
  width: auto;
  font-family: SF Pro Display, serif;
  text-transform: capitalize;
  font-size: 17px;
  padding-right: 40px;
  padding-top: 0;
  opacity: 1;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a {
    font-size: 24px;
  }
}
.dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a::after, .dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a:hover::after,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a::after,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a:hover::after {
  color: #00D0B3 !important;
  content: "▼";
  font-size: 13px;
  top: 25%;
}
.dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a:hover,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a:hover {
  color: #00D0B3 !important;
}
.dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a.collapsed::after, .dashboard-collapse pg-collapse.card.card-default > .card-header > h4 > a.collapced:hover::after,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a.collapsed::after,
.dashboard-collapse .pg-collapse.card.card-default > .card-header > h4 > a.collapced:hover::after {
  color: #00D0B3 !important;
  content: "►";
  top: 25%;
}

body .card .card-header {
  display: flex;
  justify-content: space-between;
  min-height: 0;
  min-height: 120px;
  padding: 16px 20px 14px 20px;
}
body .card .card-header .card-title {
  text-transform: none;
}

.bg-gray {
  background-color: #939393;
}

.dashboard-container .widget-title > .green {
  font-family: SF Pro Display, serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #00D0B3;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard-container .widget-title > .green {
    font-size: 24px;
  }
}

.bg-green {
  background-color: #00D0B3 !important;
}

tabset.custom-bs-tabset ul.nav-tabs {
  display: flex;
  border: none;
  flex-shrink: 0;
}
tabset.custom-bs-tabset ul.nav-tabs li.nav-item {
  flex: 1;
  text-align: center;
  border-bottom: 1px solid #BFBFBF;
}
tabset.custom-bs-tabset ul.nav-tabs li.nav-item a {
  color: #BFBFBF;
  border: none;
  font-size: 17px;
  letter-spacing: 0;
  font-family: SF Pro Display;
  text-transform: none;
}
tabset.custom-bs-tabset ul.nav-tabs li.nav-item.active {
  border-bottom: 1px solid #00D0B3;
}
tabset.custom-bs-tabset ul.nav-tabs li.nav-item.active a {
  color: #00D0B3;
  border: none;
  background: none;
}
tabset.custom-bs-tabset .tab-content {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.text-custom-dark {
  color: #333333 !important;
}

.text-custom-light-dark {
  color: #797979 !important;
}

.title-collapse {
  color: #00D0B3 !important;
}
.title-collapse:after {
  color: #00D0B3 !important;
  content: "▼";
  font-size: 13px;
  top: 25%;
}
.title-collapse.collapsed:after {
  color: #00D0B3 !important;
  content: "►";
  top: 25%;
}

.widget-9 .card-body,
.widget-9 .card-header {
  overflow: hidden;
}

.widget-9 .slide-block {
  opacity: 1;
  transform: translateY(0);
}

.card-title {
  opacity: 1;
  transform: translateY(0);
}

.col-6:nth-child(1) .widget-9 .card-title,
.col-6:nth-child(1) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 150ms;
}

.col-6:nth-child(2) .widget-9 .card-title,
.col-6:nth-child(2) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 300ms;
}

.col-6:nth-child(3) .widget-9 .card-title,
.col-6:nth-child(3) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 450ms;
}

.col-6:nth-child(4) .widget-9 .card-title,
.col-6:nth-child(4) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 600ms;
}

.col-6:nth-child(5) .widget-9 .card-title,
.col-6:nth-child(5) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 750ms;
}

.col-6:nth-child(6) .widget-9 .card-title,
.col-6:nth-child(6) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 900ms;
}

.col-6:nth-child(7) .widget-9 .card-title,
.col-6:nth-child(7) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1050ms;
}

.col-6:nth-child(8) .widget-9 .card-title,
.col-6:nth-child(8) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1200ms;
}

.col-6:nth-child(9) .widget-9 .card-title,
.col-6:nth-child(9) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1350ms;
}

.col-6:nth-child(10) .widget-9 .card-title,
.col-6:nth-child(10) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1500ms;
}

.col-6:nth-child(11) .widget-9 .card-title,
.col-6:nth-child(11) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1650ms;
}

.col-6:nth-child(12) .widget-9 .card-title,
.col-6:nth-child(12) .widget-9 .slide-block {
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1800ms;
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  15% {
    opacity: 0;
    transform: translateY(-300px);
  }
  16% {
    opacity: 0;
    transform: translateY(300px);
  }
  31% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.mh-1 {
  min-height: 1px !important;
}

.mw-1 {
  min-height: 1px !important;
}

.opacity-01 {
  opacity: 0.1;
}

.doc-card-header {
  display: grid !important;
  grid-template-columns: 400px 1fr 1fr;
  gap: 20px !important;
  min-height: 0;
  height: 100px;
  padding: 16px 20px 0 20px;
}

.no-border > div {
  border: none !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}