.open-basket,
.modal-open {
  overflow: hidden !important;
}

.modal .modal-dialog {
  &.modal-notify {
    @media (min-width: 576px) {
      max-width: 400px;
    }

    max-width: 400px !important;

    .modal-content {
      border-radius: 10px;
    }

    &.basket-error {
      margin-right: 40px;
      margin-left: auto;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      min-height: calc(100% - (1.75rem * 2));

      @media (max-width: 800px) {
        margin: 0 auto;
      }
    }
  }

  &.modal-notify-small {
    @media (min-width: 576px) {
      max-width: 340px;
    }

    max-width: 340px !important;

    .modal-content {
      border-radius: 10px;
    }

    @media (max-width: 420px) {
      max-width: unset !important;
      margin: 0;
      align-items: flex-end;
      min-height: 100%;

      .modal-content {
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px 0px 15px 0px rgba(18, 16, 104, 0.15);
      }
    }
  }

  &.modal-dialog-right {
    margin-right: 0;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 576px) {
      max-width: 100% !important;
      width: 100% !important;
    }

    .modal-content {
      border: none;
    }
  }

  &.modal-dialog-md {
    @media (min-width: 768px) {
      max-width: 760px;
      width: 760px;
    }
  }

  .icon-closed {
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z' fill='%23878F9F'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: background-position 150ms ease, opacity 60ms linear;
    width: 16px;
    height: 16px;
  }
}
